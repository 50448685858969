import { Component, OnDestroy, OnInit } from '@angular/core';
import { Column, ColumnType } from '../../../../SGRE-shared/models/tableConfiguration';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { OrderRequestsService } from '../services/order-requests.service';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';

@Component({
  selector: 'app-order-requests',
  templateUrl: './order-requests.component.html',
  styleUrl: './order-requests.component.scss'
})
export class OrderRequestsComponent implements OnInit, OnDestroy {

  public columns: Column[];
  public column1: Column[];
  userRolesPurchaser: boolean = false;
  userRolesCustomer: boolean = false;
  public orderRequestList: Observable<any>
  public orderRequestListData: any[];
  public message = 'noOrderRequest';
  public legalEntities;
  private unsubscribe$ = new Subject<void>();

  // Pagination
  public first = 0;
  public rows = 20;
  public totalRecords;

  // Filters 
  public filterQuery: string = '';
  public filterLegalEntiies: any[] = [];
  public filterRequestedBy: any[] = [];
  public filterProductCount: any[] = [];

  constructor(
    private storageService: StorageService,
    private orderRequestService: OrderRequestsService,
    private router: Router,
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    this.legalEntities = this.storageService.userLegalEntities
      ? this.storageService.userLegalEntities
      : [];
    this.roleCheck(this.storageService.userRoles);
    this.getOrderRequestList();
    this.handleSubscriptions();
  }

  handleSubscriptions() {
    // Reset Pagination
    this.globalService.orderRequestPagination$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.first = 0;
      })

    this.orderRequestList = this.orderRequestService.data$
      .pipe(
        map(data => {
          this.orderRequestListData = data?.results;
          this.totalRecords = data?.pagination?.totalResults;
          this.handleFilters(data);
          return data?.results;
        })
      );
  }

  getOrderRequestList() {
    const requestObject = {
      currentPage: 0,
      pageSize: this.rows,
      fields: 'BASIC',
      query: ':date-desc' + this.filterQuery
    }
    this.globalService.orderRequestPaginationReset.next(true);
    this.orderRequestService.getOrderRequestList(requestObject);
  }


  findLegalEntityName(uid) {
    return this.legalEntities?.find(item => item.uid === uid)?.name;
  }

  findLegalEntityUid(name) {
    return this.legalEntities?.find(item => item.name === name).uid;
  }

  handleFilters(data) {
    this.filterLegalEntiies = data?.facets?.filter(value => value.name === 'legalEntity')?.at(0)?.values?.map((value) => ({ legalEntity: this.findLegalEntityName(value?.name), selected: value?.selected }));
    this.filterRequestedBy = data?.facets?.filter(value => value.name === 'requestedBy')?.at(0)?.values?.map((value) => ({ orderRequestedBy: value?.name?.split('|')?.at(1), selected: value?.selected, value: value?.name }));
    this.filterProductCount = data?.facets?.filter(value => value.name === 'lineItemCount')?.at(0)?.values?.map((value) => ({ productsCount: value?.name, selected: value?.selected }));
    if (!this.columns && (this.filterLegalEntiies?.length > 0 || data?.results?.length === 0)) {
      this.createTableConfiguration();
    }
  }

  filterOrderRequest(data) {
    let query = '';
    for (let control of Object.keys(data)) {
      let columnName = control;
      if (columnName === 'productsCount') {
        columnName = 'lineItemCount';
      } else if (columnName === 'orderRequestedBy') {
        columnName = 'requestedBy';
      }
      data[control]?.forEach(item => {
        if (columnName === 'requestedBy') {
          query += `:${columnName}:` + `"${this.getRequestedValue(item)}"`;
        } else if (columnName === 'legalEntity') {
          query += `:${columnName}:` + `"${this.findLegalEntityUid(item)}"`;
        } else if (columnName === 'lineItemCount') {
          query += `:${columnName}:` + `${item}`;
        } else {
          query += `:${columnName}:` + `"${item}"`;
        }
      })
    }
    this.filterQuery = query;
    this.getOrderRequestList();
  }

  getRequestedValue(data) {
    return this.filterRequestedBy.filter(item => item['orderRequestedBy']?.includes(data))?.at(0)?.value;
  }

  roleCheck(roles: string[] | string) {
    if (roles?.length > 0) {
      let rolesArr = typeof (roles) === 'string' ? JSON.parse(roles) : roles;
      this.userRolesPurchaser = rolesArr.some(val => val === 'Purchaser');
    }
  }

  createTableConfiguration() {
    let columnData = [
      { label: "Legal Entity", name: "legalEntity", type: ColumnType.text, filter: true, filterOptions: this.filterLegalEntiies },
      { label: "Requested By", name: "orderRequestedBy", type: ColumnType.text, filter: true, filterOptions: this.filterRequestedBy },
      { label: "Product Count", name: "productsCount", type: ColumnType.number, filter: true, filterOptions: this.filterProductCount },
      { label: "Total Cost", name: "totalPrice", type: ColumnType.price },
      { label: "Requested On", name: "requestDate", type: ColumnType.date },
      { label: "Action", name: "action", type: ColumnType.info },
    ];
    this.columns = columnData.map(item => new Column(item));
  }

  reviewClick(data) {
    this.globalService.loadingSubject.next(true);
    const { legalEntity } = data;
    const userLegalEntities = this.storageService.userLegalEntities;
    const changedIndex = userLegalEntities.findIndex(
      (entity) => entity.name === legalEntity
    );
    this.globalService.updateLegalEntity(changedIndex, true);
    this.router.navigate([AppConstants.routeUrls.checkout], { queryParams: { cartGroupId: data?.cartGroupId, review: true } });
  }

  onPageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
    const requestObject = {
      currentPage: event.page,
      pageSize: this.rows,
      fields: 'FULL',
      query: ':date-desc' + this.filterQuery
    };
    this.globalService.loadingSubject.next(true);
    this.orderRequestService.getOrderRequestList(requestObject);
  }

  addArrayFilter(testArrayData, filterKey) {
    let array = testArrayData?.filter((obj, pos, arr) => {
      return arr?.map(mapObj => mapObj[filterKey]).indexOf(obj[filterKey]) === pos;
    });
    return array?.filter((item) => {
      return item[filterKey] !== undefined
    }).map((c) => c[filterKey]
    )
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}
